$(function () {
  // form submits for form layout
  $("#form-submit").click(function (e) {
    var $form = $(e.target).closest("body").find("form")[0];
    
    // special treatment for template forms
    if (window.template != null) {
      e.preventDefault();
      var input = $("<input>")
        .attr("type", "hidden")
        .attr("name", "template[template_sections]")
        .val(
          JSON.stringify(window.template.template_sections ? window.template.template_sections : [])
        );
      $($form).append(input);
    }
    
    $form.submit();
  });

  $(".js-select2").select2({
    theme: "bootstrap",
    allowClear: true,
  });

  // hide elements containing select2 until they are rendered
  $(".js-select2-hidden").css("visibility", "initial");

  $("#unit-type").on("change", function (e) {
    var target = e.target;
    var text = target[target.selectedIndex].text;
    var type = text.includes(":") ? text.match(/(?:\s).*/)[0] : text;
    window.location.href = "/units/new" + "?" + $.param({ type: type.trim() });
  });

  // template recurring
  $(".js-template-recurring").on("change", "input[type='checkbox']", function (e) {
    var checkbox = $(this);
    if (checkbox.prop("checked")) {
      $("#template-recurring").removeClass("d-none");
    } else {
      $("#template-recurring").addClass("d-none");
    }
  });

  // template form permissions
  $(".js-template-permissions").on("change", "input[type='radio']", function (e) {
    var value = e.target.value;
    if (value === "restrict") {
      $("#template-permissions .js-select2").prop("disabled", false);
      $("#template-permissions").removeClass("d-none");
      $("#template-permissions .select2-search__field").css("width", "400px")
    } else {
      $("#template-permissions").addClass("d-none");
      $("#template-permissions .js-select2").prop("disabled", true);
    }
  });

  // Task form validation
  // Bootstrap requires parent form to have the "was-validated" class
  $("#template-form.autosave input[type=submit]").click(function(e) {

    // intercept the submit
    e.preventDefault();

    var $form = $("#template-form form");

    $form.addClass('was-validated');
    var selection_names = $.map($(".form-check-input"), function (o) {
      return o["name"];
    });

    var selection_names = new Set(selection_names);

    // add rules to the form for each selection
    // add errors when a required multi-select is unchecked
    var errors = [];

    selection_names.forEach((sn) => {
      if (!$(`[name="${sn}"]:checked`).length > 0) {
        errors.push(sn);
        $(`[name="${sn}"]`)
          .parents(".form-group")
          .find(".alert")
          .removeClass("d-none");
      }
    });

    if(errors.length === 0 && $form.find(".form-control:invalid").length == 0) {
      $form.submit();
    }
  });

  $(".custom-file-input").on("change", function () {
    $(this).siblings(".upload-file-info").html(this.files[0].name);
  });

  // template form toggle
  $("[name='template[form_type]']").on('change', function(e){
    if (e.target.value == 'closing') {
      $('.form-remediator').addClass('hidden');
      $('.form-approver').addClass('hidden');
    } else if (e.target.value == 'followup') { 
      $('.form-remediator').removeClass('hidden');
      $('.form-approver').addClass('hidden');
    } else if (e.target.value == 'approval') {
      $('.form-remediator').removeClass('hidden');
      $('.form-approver').removeClass('hidden');
    }
  });

  // Handle assignment dropdown button clicks
  $('body').on('click', '.assign-button', function(){
    $($(this).data('target')).find('button').click();
  })

  $('.workflow-button').on('click', function(e){
    $('[name=note_commit]').click();
  })

  $('input[type=file]').on('change', function(e){
    if (!e.target.accept.includes($(this)[0].files[0].type)) {
      $(this).addClass('is-invalid');
      $(this).val(null);
     } 
     else {
       $(this).removeClass('is-invalid');
     }
  });

  // Check for unsaved notes on submit
  $('.js-check-for-notes').on('submit', function(e){
    e.preventDefault();
    var $noteField = $(this).closest('main').find('#note-text');
    var $fileField = $(this).closest('main').find('#note-file');
    if($noteField.val().length > 0 || $fileField.val() !== '') {
      if(confirm("Hold on. Looks like you have an unsaved note. Do you want to continue without saving?")) {
        this.submit();
      } else {
        return
      }
    } else {
      this.submit();
    }
  });

});
