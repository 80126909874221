$(function () {
  if ($("#template-form.autosave form").length > 0) {

    function autosave(e) {

      // don"t run if there are validation errors
      if ($("#template-form form .is-invalid").length) { return }

      //temporarily disable submit button
      // $("input[type="submit"]").prop("disabled", true);

      // display Autosaving... text
      $(".autosave-indicator").show();
      setTimeout(function(){ 
        $(".autosave-indicator").hide();
      }, 500);

      // // update the task badge in header
      // if ($(".page-header .badge").text() == "Open") {
      //   $(".page-header .badge").addClass("badge-autosaved").text("In Progress").removeClass("badge-open");
      // }

      var input = $(e.target);
      var form = input.closest("form");
      form[0].dispatchEvent(new Event('submit', { bubbles: true }));
    }

    $("#template-form.autosave").on("click", "input[type=checkbox], input[type=radio]", function(e) { autosave(e) });
    $("#template-form.autosave").on("blur", "input[type=text], input[type=number], textarea", function(e) { autosave(e) });
    $("#template-form.autosave").on("change", "input[type=date], input[type=file]", function(e) { autosave(e) });
    // $(".sigCanvas").on("mouseup", _.debounce(function(e) { autosave(e) }, 200));
    $(".signature-pad").on("sigChanged", _.debounce(function(e) { autosave(e) }, 200));

    // To prevent autosave problems, disable submit button on input focus
    // $("#template-form.autosave input[type!=submit], #template-form.autosave textarea").on("focus", function(){
    //   $("input[type="submit"]").prop("disabled", true);
    // });

    // To prevent blur event problems with tabbing, 
    // only enable the submit button if the newly-focused element is not another input.
    // $("#template-form.autosave input[type!=submit], #template-form.autosave textarea").on("blur", function(e){
    //   if (!["text", "date", "checkbox", "radio"].includes(document.activeElement.type)) {
    //     $("input[type="submit"]").prop("disabled", false);
    //   }
    // });
  }
});
