$(function () {
  // enable tooltips
  $('[data-toggle="tooltip"]').tooltip();

  $('[data-target*="-user-modal"]').on('click', function(e) {
    var button = $(this);
    var modal = $(button.data('target'));

    if (button.data('target') != '#add-user-modal') {
      modal.find('option').show();

      // set the correct form action and form field values
      modal.find('form').attr('action', `/users/${button.data('userid')}`);
      modal.find('#user_first_name').val(button.data('firstname'));
      modal.find('#user_last_name').val(button.data('lastname'));
      modal.find("#user_email").val(button.data('email'));
      modal.find('#user_admin').attr('checked', button.data('admin') != null);
      modal.find("#user_readable_name").html(`${button.data("username")}?`);

      modal.find(`option[value="${button.data('userid')}"]`).hide();
    }
  });

  $('#edit-profile-modal form').on('submit', function(e) {
    var form = $('#edit-profile-modal form').get(0);
    var successMsg = $("#edit-profile-modal .success-message");
    successMsg.addClass("hidden");

    e.preventDefault();
   
    $.ajax({
      type: "PUT",
      url: $(form).attr('action'),
      data: $(form).serialize(),
      success: function() {
        successMsg.removeClass("hidden");
      },
      complete: function() {
        
      }
    });
  });

  $('#edit-user-modal [for=user_admin]').on('click', function(e){
    $('[name="user[admin]"]').click()
  });

  $('[data-toggle="print"]').on('click', function(){
    window.print();
  });
});
