$(function () {

  function renumberBundleTemplates() {
    $('.bundle-template:visible').each(function (i) {
      $(this).find('.bundle-template-number').text(i + 1 + ".");
    });
  }

  //
  $("#template-tabs a").on("shown.bs.tab", function(e) {
    var activeTab = e.target.name;
    var otherTag = activeTab == "templates" ? "bundles" : "templates";
    var $activeButton = $(`#new-${activeTab}-button`);
    var $otherButton = $(`#new-${otherTag}-button`);

    $otherButton.addClass("d-none");
    $activeButton.removeClass("d-none");
  });

  // Update template list on unit type change
  $('.js-bundle-unit-type').on('change', function (e) {
    // clear the templates
    $('#bundle-templates').empty();
    var unitTypeId = $(this).val();
    $.get(`/api/unit_types/${unitTypeId}/templates`, function(data) {
      $('#bundle-template-select').select2('destroy').empty().select2({ 
        data: data.templates,
        theme: "bootstrap"
      });
    });
  });

  // After add a new bundle template, renumber the templates
  $('form.add-template')
    .on('ajax:success', function(event) {
      const [data, status, xhr] = event.detail;
      if(xhr.status === 200) {
        renumberBundleTemplates();
        $('[data-toggle="tooltip"]').tooltip();
      }
    });

  // Remove bundle template on bundle form
  $('body').on('click', '.js-remove-bundle-template', function(e){
    e.preventDefault();
    if(confirm("Are you sure you want to remove this template?")) {
      var $template = $(this).closest('.bundle-template');
      $template.find('.destroy-field').val('1');
      $template.hide();
      renumberBundleTemplates();
    }
  });
});